<template>
  <div id="paymentTypeSelector">
    <q-list bordered class="rounded-borders q-mb-lg">
      <q-expansion-item
        v-for="(room, idx) of rooms"
        :key="idx"
        expand-separator
        icon="mdi-bed-king"
        :label="room.selectedRoom?.description"
        :caption="
          room.addRoomResult?.roomSum.toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          })
        ">
        <q-card>
          <q-card-section>
            <selected-room :room="room" />
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

    <div class="row justify-center q-col-gutter-lg">
      <div
        v-for="paymentType in paymentTypes"
        :key="paymentType.Id"
        class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <q-card style="height: 100%">
          <q-card-section class="row">
            <div class="col-6" v-html="paymentType.Info"></div>
            <div class="col-6 text-right">
              <div v-if="paymentType.PayType === 'CARD'">
                Предоплата {{ paymentType.PayProc }} %
              </div>
              <div v-if="paymentType.PayType === 'NOPAY'">
                Оплата {{ paymentType.PayProc }} %
              </div>
              <div class="text-weight-bold text-body1">
                {{
                  paymentType.PaySum.toLocaleString("ru-RU", {
                    style: "currency",
                    currency: "RUB",
                  })
                }}
              </div>
            </div>

            <div class="col-12 row justify-end q-mt-sm">
              <pay-btn
                v-if="paymentType.allowSPB && paymentType.PayType !== 'NOPAY'"
                class="q-mr-sm"
                :payment-type-id="paymentType.Id"
                :bank="paymentType.bank"
                :payment-method="'sbp'"></pay-btn>
              <pay-btn
                v-if="paymentType.allowDeep && paymentType.PayType !== 'NOPAY'"
                class="q-mr-sm"
                :payment-type-id="paymentType.Id"
                :bank="paymentType.bank"
                :payment-method="'deep'"></pay-btn>
              <pay-btn
                v-if="paymentType.allowCard && paymentType.PayType !== 'NOPAY'"
                :payment-type-id="paymentType.Id"
                :bank="paymentType.bank"
                :payment-method="'card'"></pay-btn>
              <pay-btn
                v-if="paymentType.PayType === 'NOPAY'"
                :payment-type-id="paymentType.Id"
                :payment-method="'nopay'"></pay-btn>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useSearchStore } from "@/stores/searchStore";
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import SelectedRoom from "./SelectedRoom.vue";
import { usePaymentStore } from "@/stores/paymentStore";
import PayBtn from "./PayBtn.vue";

import VueScrollTo from "vue-scrollto";

export default defineComponent({
  computed: {
    ...mapState(useSearchStore, ["rooms"]),
    ...mapState(usePaymentStore, ["paymentTypes"]),
  },
  methods: {
    ...mapActions(usePaymentStore, ["getPaymentTypes"]),
  },
  created() {
    this.getPaymentTypes();
  },

  mounted() {
    setTimeout(() => {
      console.log("scroll paymentTypeSelector");

      VueScrollTo.scrollTo("#paymentTypeSelector", 1000, { offset: -20 });
    }, 700);

    // console.log(el);
  },
  components: { SelectedRoom, PayBtn },
});
</script>
