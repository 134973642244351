<template>
  <div>
    <q-btn
      dark
      v-if="paymentMethod === 'sbp'"
      :loading="waitPayment"
      @click="payment(paymentTypeId, 'sbp')">
      <q-icon class="q-mr-sm" size="20px">
        <img src="@/assets/bankIcons/sbp.svg" />
      </q-icon>
      <div>СБП</div>
    </q-btn>

    <q-btn
      dark
      v-if="paymentMethod === 'deep'"
      :loading="waitPayment"
      style="color: white"
      class="sberBtn"
      @click="payment(paymentTypeId, 'deep')">
      <q-icon class="q-mr-sm" size="20px">
        <img src="@/assets/bankIcons/sber.svg" />
      </q-icon>
      <div>PAY</div>
    </q-btn>

    <q-btn
      v-if="paymentMethod === 'card'"
      :loading="waitPayment"
      color="primary"
      @click="payment(paymentTypeId, 'card')">
      <q-icon name="credit_card" class="q-mr-sm" size="20px" />
      <div>КАРТА</div>
    </q-btn>

    <q-btn
      v-if="paymentMethod === 'nopay'"
      :loading="waitPayment"
      color="primary"
      @click="payment(paymentTypeId, 'card')">
      <q-icon name="payments" class="q-mr-sm" size="20px" />
      <div>Забронировать</div>
    </q-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TypePaymentMethod, usePaymentStore } from "@/stores/paymentStore";
import { mapActions, mapState } from "pinia";
import { TypeBanks } from "@/stores/paymentStore";

export default defineComponent({
  data: () => ({
    slide: 0,
  }),

  computed: {
    ...mapState(usePaymentStore, ["paymentTypes", "waitPayment"]),
  },

  methods: {
    ...mapActions(usePaymentStore, ["payment"]),
  },

  props: {
    paymentTypeId: {
      type: Number,
      required: true,
    },
    bank: {
      type: String as PropType<TypeBanks>,
    },
    paymentMethod: {
      type: String as PropType<TypePaymentMethod>,
      required: true,
    },
  },
});
</script>

<style scoped>
.sberBtn {
  background-image: linear-gradient(150deg, #f2ea00, #03d31d 35%, #0fb6fe);
}
</style>
