<template>
  <div>
    <div v-if="!actualRoom.addRoomResult">
      <transition appear name="fade">
        <room-selector v-if="actualRoom.roomStep === 'room'" />
      </transition>

      <transition appear name="fade">
        <tariff-selector v-if="actualRoom.roomStep === 'tariff'" />
      </transition>
    </div>

    <div v-else>
      <selected-room :room="actualRoom" />
      <div class="row justify-center q-mt-md">
        <q-btn class="q-mr-md" @click.once="deleteActualRoom()">
          Изменить выбор
        </q-btn>
        <q-btn color="primary" @click.once="step++">Продолжить</q-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import RoomSelector from "./RoomSelector.vue";
import TariffSelector from "./TariffSelector.vue";
import { useSearchStore } from "@/stores/searchStore";
import { mapState, mapWritableState, mapActions } from "pinia";
import SelectedRoom from "./SelectedRoom.vue";

export default defineComponent({
  components: { RoomSelector, TariffSelector, SelectedRoom },

  computed: {
    ...mapState(useSearchStore, ["actualRoom"]),
    ...mapWritableState(useSearchStore, ["step"]),
  },

  methods: {
    ...mapActions(useSearchStore, ["deleteActualRoom"]),
  },

  created() {
    console.log("created main selector");

    this.actualRoom.roomStep = "room";
    if (!this.actualRoom.addRoomResult) delete this.actualRoom.selectedRoom;
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
