import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import { createPinia } from "pinia";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

createApp(App)
  .use(createPinia())

  // eslint-disable-next-line
  .use(VueTelInput as any)
  .use(Quasar, quasarUserOptions)
  .mount("#appONL4");
