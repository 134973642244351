<template>
  <q-card
    class="column"
    style="height: 100%"
    v-if="room"
    :class="{
      'bg-grey-5': roomType === 'other' && !room.otherSearch.length,
    }">
    <!-- верх -->
    <div class="col-auto">
      <q-carousel
        height="300px"
        swipeable
        arrows
        animated
        v-model="slide"
        thumbnails
        infinite>
        <q-carousel-slide
          v-for="(item, photoIdx) in room.photo.items"
          :key="photoIdx"
          :name="photoIdx"
          :img-src="baseUrl + '/client_data/image/' + item.file" />

        <template v-slot:control>
          <q-carousel-control position="top-right" :offset="[18, 18]">
            <q-btn
              push
              round
              dense
              color="white"
              text-color="primary"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              @click="fullscreen = !fullscreen" />
          </q-carousel-control>
        </template>
      </q-carousel>
    </div>

    <!-- центр -->
    <div class="col-auto q-mx-md q-mt-sm">
      <!-- название -->
      <div class="row">
        <q-img
          class="q-mr-sm"
          v-if="room.promotion_file"
          width="30px"
          height="30px"
          :src="baseUrl + '/client_data/image/' + room.promotion_file" />
        <div class="text-h6 q-mb-sm" v-html="room.description"></div>
      </div>
      <!-- описание -->
      <div
        class="text-body2"
        style="white-space: pre-line"
        v-html="room.info"></div>
    </div>

    <q-space class="col-auto"></q-space>

    <!-- низ -->
    <div class="col-auto q-ma-md">
      <!-- available -->
      <div
        v-if="roomType === 'available'"
        style="margin-top: 10px"
        class="row justify-between">
        <div
          class="col-auto"
          style="font-size: large; display: flex; align-items: center">
          От
          {{
            room.minPrce?.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })
          }}
        </div>

        <!-- гости/ночи -->
        <div
          class="col-auto q-mx-sm"
          style="display: flex; align-items: center">
          {{ formatGuestString(room.guests.length) }} /
          {{ formattedSelectedDate.days }}
          {{ formateDayNight(formattedSelectedDate.days) }}
        </div>

        <div class="q-mt-xs col-sm-auto col-xs-12 row justify-end">
          <q-btn
            color="primary"
            @click="
              actualRoom.selectedRoom = room;
              actualRoom.roomStep = 'tariff';
            ">
            Выбрать номер
          </q-btn>
        </div>

        <q-banner
          style="width: 100%"
          dense
          class="bg-warning text-center q-mt-sm"
          v-if="roomType === 'available' && room.rooms === 1">
          Остался последний номер
        </q-banner>
      </div>

      <!-- other -->
      <div class="col-12" v-if="roomType === 'other'">
        <template v-if="room.otherSearch.length">
          <q-card>
            <q-banner
              style="width: 100%; margin-top: 10px"
              dense
              class="bg-warning text-center">
              Доступные даты заселения
            </q-banner>
            <q-list
              bordered
              separator
              v-for="(other, otherIdx) in room.otherSearch"
              :key="otherIdx">
              <q-item>
                <q-item-section
                  >C {{ formatTime(other.date_in) }} по
                  {{ formatTime(other.date_in, other.days) }},
                  {{ other.days }}
                  {{ formateDayNight(other.days) }}
                </q-item-section>

                <q-item-section>
                  <q-btn @click.once="selectOtherRoom(other)">
                    Изменить даты
                  </q-btn>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card>
        </template>
        <q-banner
          style="width: 100%; margin-top: 10px"
          dense
          class="text-center"
          v-else>
          Свободных мест нет
        </q-banner>
      </div>
    </div>

    <q-dialog full-width full-height v-model="fullscreen">
      <q-carousel
        style="
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.322);
        "
        swipeable
        arrows
        animated
        v-model="slide"
        infinite>
        <q-carousel-slide
          style="background-color: none"
          v-for="(item, photoIdx) in room.photo.items"
          :key="photoIdx"
          :name="photoIdx">
          <q-img
            fit="contain"
            style="height: 100%; width: 100%"
            :src="baseUrl + '/client_data/image/' + item.file" />
        </q-carousel-slide>

        <template v-slot:control>
          <q-carousel-control position="top-right" :offset="[18, 18]">
            <q-btn
              push
              round
              dense
              color="white"
              text-color="primary"
              :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
              @click="fullscreen = !fullscreen" />
          </q-carousel-control>
        </template>
      </q-carousel>
    </q-dialog>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TypeRoom } from "@/stores/searchStore";
import { useSessionStore } from "@/stores/sessionStore";
import { mapState, mapActions } from "pinia";
import { useSearchStore } from "@/stores/searchStore";
import moment from "moment";

export default defineComponent({
  data: () => ({
    slide: 0,
    fullscreen: false,
  }),

  props: {
    room: Object as PropType<TypeRoom>,
    roomType: String as PropType<"available" | "other">,
  },

  computed: {
    ...mapState(useSearchStore, ["actualRoom", "formattedSelectedDate"]),
    ...mapState(useSessionStore, ["baseUrl", "config"]),
  },

  methods: {
    ...mapActions(useSearchStore, ["selectOtherRoom", "formateDayNight"]),
    fromatImages(
      photos: {
        file: string;
        sort_id: number;
        description: string;
      }[]
    ) {
      const fomrmattedPhotos = [];
      for (const item of photos) {
        fomrmattedPhotos.push(this.baseUrl + "/client_data/image/" + item.file);
      }
      return fomrmattedPhotos;
    },
    formatTime(date: string, days?: number): string {
      if (days) {
        return moment(date)
          .add(days, "days")
          .add(this.config.calc_night ? 0 : -1, "days")
          .format("DD.MM.YYYY");
      } else {
        return moment(date).format("DD.MM.YYYY");
      }
    },

    formatGuestString(length: number): string {
      if (length === 1) return "1 гость";
      if (length >= 2 && length <= 4) return length + " гостя";
      return length + " гостей";
    },
  },
});
</script>
