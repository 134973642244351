import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row q-col-gutter-lg" }
const _hoisted_4 = {
  key: 1,
  class: "row"
}
const _hoisted_5 = { class: "row justify-between q-mt-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_room_card = _resolveComponent("room-card")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.actualRoom.selectedRoom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.actualRoom.searchResult)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualRoom.searchResult.availableRoom, (room) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: room.id,
                      class: "col-sm-12 col-md-6 col-lg-4"
                    }, [
                      _createVNode(_component_room_card, {
                        room: room,
                        roomType: "available"
                      }, null, 8, ["room"])
                    ]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actualRoom.searchResult.otherRoom, (room) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: room.id,
                      style: {"height":"auto"},
                      class: "col-sm-12 col-md-6 col-lg-4"
                    }, [
                      _createVNode(_component_room_card, {
                        room: room,
                        roomType: "other"
                      }, null, 8, ["room"])
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (n) => {
                  return _createElementVNode("div", {
                    class: "col-sm-12 col-md-6 col-lg-4",
                    key: n,
                    style: {"padding":"10px","min-height":"100px"}
                  }, [
                    _createVNode(_component_q_card, { style: {"height":"100%","width":"100%"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_skeleton, {
                          height: "200px",
                          square: ""
                        }),
                        _createVNode(_component_q_card_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_skeleton, {
                              type: "text",
                              width: "30%",
                              class: "text-h6"
                            }),
                            _createVNode(_component_q_skeleton, {
                              type: "text",
                              class: "text-subtitle1"
                            }),
                            _createVNode(_component_q_skeleton, {
                              type: "text",
                              width: "50%",
                              class: "text-subtitle1"
                            }),
                            _createVNode(_component_q_skeleton, {
                              type: "text",
                              width: "80%",
                              class: "text-subtitle1"
                            }),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_q_skeleton, {
                                type: "text",
                                width: "30%",
                                class: "text-subtitle1"
                              }),
                              _createVNode(_component_q_skeleton, {
                                type: "QBtn",
                                width: "150px"
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                }), 64))
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}