import { defineStore } from "pinia";
import moment from "moment";
import axiosInst from "@/plugins/axios";
import { useSessionStore } from "./sessionStore";
import { connectionError, notify, simpleError } from "@/plugins/notify/notify";

//store//
export const useSearchStore = defineStore("search", {
  state: () => ({
    //dateSelector
    selectedDateRange: [
      moment().add(7, "days").toDate(),
      moment().add(14, "days").toDate(),
    ] as Date[],

    dateSelectorDialog: false,

    rooms: [{ search: { adults: 1, children: [] } }] as TypeRoomMain[],
    customerData: { customerFIO: "", email: "", phone: "" },

    step: -1 as number,
  }),

  actions: {
    async findRooms() {
      // callBack
      const rooms = [];
      for (const room of this.rooms) {
        rooms.push(room.search);
      }
      useSessionStore().callBack("search", {
        date: this.formattedSelectedDate,
        searchData: rooms,
      });
      //

      this.step = -1;
      await this.cancelAllRooms();

      this.dateSelectorDialog = false;

      setTimeout(() => {
        this.step = 0;
      }, 0);
    },

    async searchRooms() {
      delete this.actualRoom.searchResult;
      delete this.actualRoom.querySearch;
      delete this.actualRoom.selectedRoom;
      delete this.actualRoom.addRoomResult;

      const division = window.onlDivision;
      const startDate = moment(this.selectedDateRange[0]).format("YYYY-MM-DD");

      const days =
        moment(this.selectedDateRange[1])
          .startOf("day")
          .diff(moment(this.selectedDateRange[0]).startOf("day"), "days") +
        (useSessionStore().config.calc_night ? 0 : 1);

      // room

      const guest =
        this.actualRoom.search.adults + this.actualRoom.search.children.length;

      let children = "";
      for (const child of this.actualRoom.search.children) {
        children = children + "&age[]=" + child;
      }

      await axiosInst()
        .get(
          `/search?division=${division}&lang=ru&date=${startDate}&days=${days}&guest=${guest}${children}`
        )
        .then(({ data }) => {
          if (data.result === "success") {
            this.actualRoom.searchResult = data.data;
            this.actualRoom.querySearch = data.query;

            // вычисление минимальных цен комнат
            if (this.actualRoom.searchResult) {
              for (const room of this.actualRoom.searchResult.availableRoom) {
                let minPrice = 0;
                for (const guest of room.guests) {
                  let minGuestPrice = Infinity;
                  for (const tariff of guest.tariffs) {
                    if (tariff.fullPrice < minGuestPrice)
                      minGuestPrice = tariff.fullPrice;
                  }
                  if (minGuestPrice !== Infinity) {
                    minPrice = minPrice + minGuestPrice;
                  }
                }
                room.minPrce = minPrice;
              }
            }
          }
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            await useSessionStore().createNewSession();
            await this.searchRooms();
            return;
          }
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });
    },

    async addRoom() {
      // roomSumm
      let roomSumm = 0;
      if (this.actualRoom.selectedRoom && this.actualRoom.querySearch) {
        for (const guest of this.actualRoom.selectedRoom.guests) {
          if (guest.selectTariff)
            roomSumm = roomSumm + guest.selectTariff.fullPrice;
        }
        //

        // callBack
        useSessionStore().callBack("addRoomToCart", {
          roomId: this.actualRoom.selectedRoom.id,
          roomDescription: this.actualRoom.selectedRoom.description,
          roomSum: roomSumm,
          dateIn: this.actualRoom.querySearch?.date_in,
          dateOut: this.actualRoom.querySearch?.date_out,
          dateOutReal: moment(this.actualRoom.querySearch?.date_out)
            .add(useSessionStore().config.calc_night ? 1 : 0, "day") //TODO+
            .toISOString(),
          days: this.actualRoom.querySearch?.days,
          guest: this.actualRoom.selectedRoom?.guests,
        });
        //

        axiosInst()
          .post("/add_room", [
            {
              requestId: null, //?????????????????????????
              clusterId: null,
              roomId: this.actualRoom.selectedRoom.id,
              roomDescription: this.actualRoom.selectedRoom.description,
              roomSum: roomSumm,
              dateIn: this.actualRoom.querySearch.date_in,
              dateOut: this.actualRoom.querySearch.date_out,
              dateOutReal: moment(this.actualRoom.querySearch.date_out).add(
                useSessionStore().config.calc_night ? 1 : 0, //TODO +
                "day"
              ), //?????????????????????????
              days: this.actualRoom.querySearch.days,
              guest: this.actualRoom.selectedRoom.guests,
            },
          ])
          .then(({ data }) => {
            if (data.result === "success") {
              this.actualRoom.addRoomResult = data.data;

              //поиск незаполненных номеров
              const foundRoomIdx = this.rooms.findIndex((room) => {
                return room.addRoomResult ? false : true;
              });

              if (foundRoomIdx === -1) {
                this.step = this.rooms.length;
              } else {
                this.step = foundRoomIdx;
              }
            }
          })
          .catch(async (e) => {
            if (e?.response?.status === 401) {
              useSessionStore().timeoutError();
              return;
            }
            if (!e?.response?.status) {
              connectionError();
              return;
            }
            simpleError();
          });
      }
    },

    async deleteActualRoom() {
      // callBack
      useSessionStore().callBack(
        "deleteRoomFromCart",
        this.actualRoom.addRoomResult
      );
      //
      await axiosInst()
        .post("/cancel_room", [
          {
            clusterId: this.actualRoom.addRoomResult?.clusterId,
          },
        ])
        .then(() => {
          this.searchRooms();
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            useSessionStore().timeoutError();
            return;
          }
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });
    },

    async saveCart(consent: boolean) {
      if (!consent) {
        notify({
          type: "warning",
          message: "Пожалуйста, согласитесь с правилами.",
        });
        return;
      }

      const rooms = [];

      for (const room of this.rooms) {
        rooms.push(room.addRoomResult);
      }

      // callBack
      useSessionStore().callBack("saveCart", rooms);
      //

      await axiosInst()
        .post("/save_cart", [
          {
            customerFIO: this.customerData.customerFIO,
            email: this.customerData.email,
            phone: this.customerData.phone,
            rooms: rooms,
          },
        ])
        .then(() => {
          this.step++;
        })
        .catch(async (e) => {
          if (e?.response?.status === 401) {
            useSessionStore().timeoutError();
            return;
          }
          if (!e?.response?.status) {
            connectionError();
            return;
          }
          simpleError();
        });
    },

    async selectOtherRoom(otherRoom: TypeOtherSearch) {
      this.selectedDateRange[0] = new Date(otherRoom.date_in);
      this.selectedDateRange[1] = moment(otherRoom.date_in)
        .add(otherRoom.days, "days")
        .add(useSessionStore().config.calc_night ? 0 : -1, "days") //TODO---
        .toDate();

      this.findRooms();
    },

    async cancelAllRooms() {
      for (const room of this.rooms) {
        if (room.addRoomResult) {
          await axiosInst()
            .post("/cancel_room", [
              {
                clusterId: room.addRoomResult.clusterId,
              },
            ])
            .then(() => {
              delete room.searchResult;
              delete room.querySearch;
              delete room.selectedRoom;
              delete room.addRoomResult;
            })
            .catch(async (e) => {
              if (e?.response?.status === 401) {
                useSessionStore().timeoutError();
                return;
              }
              if (!e?.response?.status) {
                connectionError();
                return;
              }
              simpleError();
            });
        }
      }
    },

    formateAge(age: number): string {
      if (age === 1) return "1 год";
      if (age >= 2 && age <= 4) return age + " года";
      return age + " лет";
    },

    formateDayNight(length: number): string {
      if (useSessionStore().config.calc_night) {
        if (length === 1) return "ночь";
        if (length >= 2 && length <= 4) return "ночи";
        return "ночей";
      } else {
        if (length === 1) return "день";
        if (length >= 2 && length <= 4) return "дня";
        return "дней";
      }
    },
  },

  getters: {
    formattedSelectedDate: (state) => {
      return {
        from: state.selectedDateRange[0]
          ? moment(state.selectedDateRange[0]).format("DD.MM.YYYY")
          : "",
        to: state.selectedDateRange[1]
          ? moment(state.selectedDateRange[1]).format("DD.MM.YYYY")
          : "",
        days:
          state.selectedDateRange[0] && state.selectedDateRange[1]
            ? moment(state.selectedDateRange[1])
                .startOf("day")
                .diff(
                  moment(state.selectedDateRange[0]).startOf("day"),
                  "days"
                ) + (useSessionStore().config.calc_night ? 0 : 1)
            : 0,
      };
    },

    actualRoom: (state) => {
      return state.rooms[state.step];
    },
  },
});

//types//

type TypeRoomMain = {
  search: TypeRoomForSearch;
  searchResult?: TypeSearchResult;
  querySearch?: TypeQuerySearch;
  selectedRoom?: TypeRoom;
  addRoomResult?: TypeAddRoomResult;
  roomStep?: "room" | "tariff";
};

type TypeRoomForSearch = { adults: number; children: number[] };

//TypeSearchResult
type TypeSearchResult = {
  availableRoom: TypeRoom[];
  availableTariff: TypeTariff[];
  otherRoom: TypeRoom[];
  otherTariff: TypeTariff[];
};

type TypeTariff = {
  id: number;
  description: string;
  info: string;
  promotion_file: string;
  show_discount: number;
  min_price: string;
  min_age: number;
  max_age: number;
  min_days: number;
  max_days: number;
  otherDescription?: string;
};

type TypeRoom = {
  id: number;
  description: string;
  info: string;
  promotion_file: null;
  photo: {
    items: {
      file: string;
      sort_id: number;
      description: string;
    }[];
  };
  rooms: number;
  guests: TypeGuest[];
  otherText: "NO ROOM";
  otherSearch: TypeOtherSearch[];
  minPrce?: number;
};

type TypeOtherSearch = {
  date_in: string;
  allDays: number;
  days: number;
  roomCount: number;
};

type TypeGuest = {
  idx: number;
  age: number;
  isDop: boolean;
  tariffs: TypeGuestTariff[];
  selectTariff?: TypeGuestTariff;
  FIO?: string;
  SEX?: "M" | "F";
};

type TypeGuestTariff = {
  tariffId: number;
  tariffDescription: string;
  isRoom: boolean;
  fullPrice: number;
  show_discount: number;
  dayPrice: { price: number }[];
};

//
type TypeQuerySearch = {
  date_in: string;
  date_out: string;
  days: number;
  guest: number;
  age: {
    years: number;
    ext_group: number;
    onlyDop: boolean;
  }[];
  adult: number;
  child: number;
};

//
type TypeAddRoomResult = {
  requestId: string;
  clusterId: number;
  roomId: number;
  roomDescription: string;
  roomSum: number;
  dateIn: string;
  dateOut: string;
  dateOutReal: string;
  days: number;
  guest: TypeGuest[];
};

//

export { TypeRoom, TypeRoomMain, TypeGuestTariff, TypeTariff, TypeGuest };
