import "./styles/quasar.sass";
import lang from "quasar/lang/ru.js";
import "@quasar/extras/roboto-font/roboto-font.css";
// import "@quasar/extras/material-icons/material-icons.css";
// import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
// import "@quasar/extras/material-icons-round/material-icons-round.css";
// import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
// import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
// import "@quasar/extras/fontawesome-v6/fontawesome-v6.css";
// import "@quasar/extras/ionicons-v4/ionicons-v4.css";
// import "@quasar/extras/mdi-v4/mdi-v4.css";
// import "@quasar/extras/eva-icons/eva-icons.css";

import SessionStorage from 'quasar/src/plugins/SessionStorage.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  // extras: ["fontawesome-v6"],
  config: {
    brand: {
      primary: window.onlTheme?.primary || "#1976d2",
      negative: window.onlTheme?.negative || "#c10015",
      warning: window.onlTheme?.warning || "#f2c037",
    },
  },
  plugins: { SessionStorage, Notify, Dialog },
  lang: lang,
};
