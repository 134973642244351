import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "paymentTypeSelector" }
const _hoisted_2 = { class: "row justify-center q-col-gutter-lg" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "col-6 text-right" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "text-weight-bold text-body1" }
const _hoisted_8 = { class: "col-12 row justify-end q-mt-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selected_room = _resolveComponent("selected-room")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_pay_btn = _resolveComponent("pay-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_list, {
      bordered: "",
      class: "rounded-borders q-mb-lg"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rooms, (room, idx) => {
          return (_openBlock(), _createBlock(_component_q_expansion_item, {
            key: idx,
            "expand-separator": "",
            icon: "mdi-bed-king",
            label: room.selectedRoom?.description,
            caption: 
          room.addRoomResult?.roomSum.toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
          })
        
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_selected_room, { room: room }, null, 8, ["room"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["label", "caption"]))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentTypes, (paymentType) => {
        return (_openBlock(), _createElementBlock("div", {
          key: paymentType.Id,
          class: "col-xs-12 col-sm-12 col-md-6 col-lg-4"
        }, [
          _createVNode(_component_q_card, { style: {"height":"100%"} }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "row" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "col-6",
                    innerHTML: paymentType.Info
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, [
                    (paymentType.PayType === 'CARD')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Предоплата " + _toDisplayString(paymentType.PayProc) + " % ", 1))
                      : _createCommentVNode("", true),
                    (paymentType.PayType === 'NOPAY')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Оплата " + _toDisplayString(paymentType.PayProc) + " % ", 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(paymentType.PaySum.toLocaleString("ru-RU", {
                    style: "currency",
                    currency: "RUB",
                  })), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (paymentType.allowSPB && paymentType.PayType !== 'NOPAY')
                      ? (_openBlock(), _createBlock(_component_pay_btn, {
                          key: 0,
                          class: "q-mr-sm",
                          "payment-type-id": paymentType.Id,
                          bank: paymentType.bank,
                          "payment-method": 'sbp'
                        }, null, 8, ["payment-type-id", "bank"]))
                      : _createCommentVNode("", true),
                    (paymentType.allowDeep && paymentType.PayType !== 'NOPAY')
                      ? (_openBlock(), _createBlock(_component_pay_btn, {
                          key: 1,
                          class: "q-mr-sm",
                          "payment-type-id": paymentType.Id,
                          bank: paymentType.bank,
                          "payment-method": 'deep'
                        }, null, 8, ["payment-type-id", "bank"]))
                      : _createCommentVNode("", true),
                    (paymentType.allowCard && paymentType.PayType !== 'NOPAY')
                      ? (_openBlock(), _createBlock(_component_pay_btn, {
                          key: 2,
                          "payment-type-id": paymentType.Id,
                          bank: paymentType.bank,
                          "payment-method": 'card'
                        }, null, 8, ["payment-type-id", "bank"]))
                      : _createCommentVNode("", true),
                    (paymentType.PayType === 'NOPAY')
                      ? (_openBlock(), _createBlock(_component_pay_btn, {
                          key: 3,
                          "payment-type-id": paymentType.Id,
                          "payment-method": 'nopay'
                        }, null, 8, ["payment-type-id"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128))
    ])
  ]))
}