import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row justify-center q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_room_selector = _resolveComponent("room-selector")!
  const _component_tariff_selector = _resolveComponent("tariff-selector")!
  const _component_selected_room = _resolveComponent("selected-room")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.actualRoom.addRoomResult)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_Transition, {
            appear: "",
            name: "fade"
          }, {
            default: _withCtx(() => [
              (_ctx.actualRoom.roomStep === 'room')
                ? (_openBlock(), _createBlock(_component_room_selector, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_Transition, {
            appear: "",
            name: "fade"
          }, {
            default: _withCtx(() => [
              (_ctx.actualRoom.roomStep === 'tariff')
                ? (_openBlock(), _createBlock(_component_tariff_selector, { key: 0 }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_selected_room, { room: _ctx.actualRoom }, null, 8, ["room"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              class: "q-mr-md",
              onClickOnce: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteActualRoom()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Изменить выбор ")
              ]),
              _: 1
            }),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClickOnce: _cache[1] || (_cache[1] = ($event: any) => (_ctx.step++))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Продолжить")
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}