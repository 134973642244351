<template>
  <div>
    <room-card :room="room?.selectedRoom" />

    <q-card>
      <q-list bordered separator>
        <q-item>
          <q-item-section class="text-weight-bolder"> Гость </q-item-section>
          <q-item-section class="text-weight-bolder"> Тариф </q-item-section>
          <q-item-section class="text-weight-bolder"> Цена </q-item-section>
        </q-item>
        <q-item v-for="guest in room?.selectedRoom?.guests" :key="guest.idx">
          <q-item-section>
            <q-item-label>
              {{
                guest.age > -1
                  ? "Ребенок " + formateAge(guest.age)
                  : "Взрослый " + guest.idx
              }}
            </q-item-label>
          </q-item-section>
          <q-item-section>
            {{ guest.selectTariff?.tariffDescription }}
          </q-item-section>
          <q-item-section>
            {{
              guest.selectTariff?.fullPrice.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })
            }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section class="text-weight-bolder"> </q-item-section>
          <q-item-section class="text-weight-bolder"> </q-item-section>
          <q-item-section class="text-weight-bolder">
            {{
              room?.addRoomResult?.roomSum.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })
            }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TypeRoomMain } from "@/stores/searchStore";
import RoomCard from "./RoomCard.vue";
import { mapActions } from "pinia";
import { useSearchStore } from "@/stores/searchStore";

export default defineComponent({
  components: { RoomCard },

  methods: {
    ...mapActions(useSearchStore, ["formateAge"]),
  },

  props: {
    room: Object as PropType<TypeRoomMain>,
  },
});
</script>
