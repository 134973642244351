import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import "./notify.scss";

const simpleError = () => {
  Notify.create({
    message: "Произошла непредвиденная ошибка. Повторите попытку позже.",
    type: "negative",
    timeout: 100000,
    actions: [
      {
        icon: "close",
        color: "white",
        round: true,
        handler: () => {
          /* ... */
        },
      },
    ],
  });
};

///////////////////////////

const connectionError = () => {
  Notify.create({
    message: "Ошибка! Проверьте подключение к интернету!",
    type: "negative",
    timeout: 100000,
    actions: [
      {
        icon: "close",
        color: "white",
        round: true,
        handler: () => {
          /* ... */
        },
      },
    ],
  });
};

////////////////////

type TypeNotify = {
  type?: "positive" | "negative" | "warning" | "info" | "ongoing";
  message?: string;
  icon?: string;
  caption?: string;
  color?: string;
  timeout?: number;
};

const notify = ({ type, message, icon, caption, timeout }: TypeNotify) => {
  Notify.create({
    type: type,
    message: message,
    icon: icon,
    caption: caption,
    timeout: timeout,
    actions: [
      {
        icon: "close",
        color: "white",
        round: true,
        handler: () => {
          /* ... */
        },
      },
    ],
  });
};

type TypeAlert = {
  type?: "positive" | "negative" | "warning";
  title?: string;
  message?: string;
};

const BigAlert = ({ type, title, message }: TypeAlert) => {
  let icon = "";

  if (type === "negative") {
    icon = `
    <div class="f-modal-alert">
    <div class="f-modal-icon f-modal-error animate">
      <span class="f-modal-x-mark">
        <span class="f-modal-line f-modal-left animateXLeft"></span>
        <span class="f-modal-line f-modal-right animateXRight"></span>
      </span>
      <div class="f-modal-placeholder"></div>
      <div class="f-modal-fix"></div>
    </div>
    <div class="text-center">${title || "Ошибка!"}</div>
    </div>`;
  }

  if (type === "positive") {
    icon = `
    <div class="f-modal-alert">
    <div class="f-modal-icon f-modal-success animate">
      <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
      <span class="f-modal-line f-modal-long animateSuccessLong"></span>
      <div class="f-modal-placeholder"></div>
      <div class="f-modal-fix"></div>
    </div>
    <div class="text-center">${title || "Успех!"}</div>
    </div>`;
  }

  if (type === "warning") {
    icon = `
    <div class="f-modal-alert">
    <div class="f-modal-icon f-modal-warning scaleWarning">
      <span class="f-modal-body pulseWarningIns"></span>
      <span class="f-modal-dot pulseWarningIns"></span>
    </div>
    <div class="text-center">${title || "Внимание!"}</div>
    </div>`;
  }

  Dialog.create({
    title: icon,
    message: `<div class="text-center">${message}</div>`,
    html: true,
  });
};

export { simpleError, notify, connectionError, BigAlert };
