import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-auto" }
const _hoisted_2 = { class: "col-auto q-mx-md q-mt-sm" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "col-auto q-ma-md" }
const _hoisted_7 = {
  key: 0,
  style: {"margin-top":"10px"},
  class: "row justify-between"
}
const _hoisted_8 = {
  class: "col-auto",
  style: {"font-size":"large","display":"flex","align-items":"center"}
}
const _hoisted_9 = {
  class: "col-auto q-mx-sm",
  style: {"display":"flex","align-items":"center"}
}
const _hoisted_10 = { class: "q-mt-xs col-sm-auto col-xs-12 row justify-end" }
const _hoisted_11 = {
  key: 1,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_carousel_slide = _resolveComponent("q-carousel-slide")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_carousel_control = _resolveComponent("q-carousel-control")!
  const _component_q_carousel = _resolveComponent("q-carousel")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_ctx.room)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        class: _normalizeClass(["column", {
      'bg-grey-5': _ctx.roomType === 'other' && !_ctx.room.otherSearch.length,
    }]),
        style: {"height":"100%"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_q_carousel, {
              height: "300px",
              swipeable: "",
              arrows: "",
              animated: "",
              modelValue: _ctx.slide,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.slide) = $event)),
              thumbnails: "",
              infinite: ""
            }, {
              control: _withCtx(() => [
                _createVNode(_component_q_carousel_control, {
                  position: "top-right",
                  offset: [18, 18]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      push: "",
                      round: "",
                      dense: "",
                      color: "white",
                      "text-color": "primary",
                      icon: _ctx.fullscreen ? 'fullscreen_exit' : 'fullscreen',
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fullscreen = !_ctx.fullscreen))
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.room.photo.items, (item, photoIdx) => {
                  return (_openBlock(), _createBlock(_component_q_carousel_slide, {
                    key: photoIdx,
                    name: photoIdx,
                    "img-src": _ctx.baseUrl + '/client_data/image/' + item.file
                  }, null, 8, ["name", "img-src"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.room.promotion_file)
                ? (_openBlock(), _createBlock(_component_q_img, {
                    key: 0,
                    class: "q-mr-sm",
                    width: "30px",
                    height: "30px",
                    src: _ctx.baseUrl + '/client_data/image/' + _ctx.room.promotion_file
                  }, null, 8, ["src"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "text-h6 q-mb-sm",
                innerHTML: _ctx.room.description
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("div", {
              class: "text-body2",
              style: {"white-space":"pre-line"},
              innerHTML: _ctx.room.info
            }, null, 8, _hoisted_5)
          ]),
          _createVNode(_component_q_space, { class: "col-auto" }),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.roomType === 'available')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, " От " + _toDisplayString(_ctx.room.minPrce?.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })), 1),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatGuestString(_ctx.room.guests.length)) + " / " + _toDisplayString(_ctx.formattedSelectedDate.days) + " " + _toDisplayString(_ctx.formateDayNight(_ctx.formattedSelectedDate.days)), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {
              _ctx.actualRoom.selectedRoom = _ctx.room;
              _ctx.actualRoom.roomStep = 'tariff';
            })
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Выбрать номер ")
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.roomType === 'available' && _ctx.room.rooms === 1)
                    ? (_openBlock(), _createBlock(_component_q_banner, {
                        key: 0,
                        style: {"width":"100%"},
                        dense: "",
                        class: "bg-warning text-center q-mt-sm"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Остался последний номер ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.roomType === 'other')
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_ctx.room.otherSearch.length)
                    ? (_openBlock(), _createBlock(_component_q_card, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_banner, {
                            style: {"width":"100%","margin-top":"10px"},
                            dense: "",
                            class: "bg-warning text-center"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Доступные даты заселения ")
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.room.otherSearch, (other, otherIdx) => {
                            return (_openBlock(), _createBlock(_component_q_list, {
                              bordered: "",
                              separator: "",
                              key: otherIdx
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("C " + _toDisplayString(_ctx.formatTime(other.date_in)) + " по " + _toDisplayString(_ctx.formatTime(other.date_in, other.days)) + ", " + _toDisplayString(other.days) + " " + _toDisplayString(_ctx.formateDayNight(other.days)), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_btn, {
                                          onClickOnce: ($event: any) => (_ctx.selectOtherRoom(other))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Изменить даты ")
                                          ]),
                                          _: 2
                                        }, 1032, ["onClickOnce"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_q_banner, {
                        key: 1,
                        style: {"width":"100%","margin-top":"10px"},
                        dense: "",
                        class: "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Свободных мест нет ")
                        ]),
                        _: 1
                      }))
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_q_dialog, {
            "full-width": "",
            "full-height": "",
            modelValue: _ctx.fullscreen,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.fullscreen) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_carousel, {
                style: {"height":"100%","width":"100%","background-color":"rgba(0, 0, 0, 0.322)"},
                swipeable: "",
                arrows: "",
                animated: "",
                modelValue: _ctx.slide,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.slide) = $event)),
                infinite: ""
              }, {
                control: _withCtx(() => [
                  _createVNode(_component_q_carousel_control, {
                    position: "top-right",
                    offset: [18, 18]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        push: "",
                        round: "",
                        dense: "",
                        color: "white",
                        "text-color": "primary",
                        icon: _ctx.fullscreen ? 'fullscreen_exit' : 'fullscreen',
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.fullscreen = !_ctx.fullscreen))
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.room.photo.items, (item, photoIdx) => {
                    return (_openBlock(), _createBlock(_component_q_carousel_slide, {
                      style: {"background-color":"none"},
                      key: photoIdx,
                      name: photoIdx
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_img, {
                          fit: "contain",
                          style: {"height":"100%","width":"100%"},
                          src: _ctx.baseUrl + '/client_data/image/' + item.file
                        }, null, 8, ["src"])
                      ]),
                      _: 2
                    }, 1032, ["name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}