<template>
  <div>
    <div v-if="!actualRoom.selectedRoom">
      <div v-if="actualRoom.searchResult">
        <!-- доступные номера -->
        <div class="row q-col-gutter-lg">
          <div
            v-for="room of actualRoom.searchResult.availableRoom"
            :key="room.id"
            class="col-sm-12 col-md-6 col-lg-4">
            <room-card :room="room" roomType="available" />
          </div>

          <!-- недоступные номера -->
          <div
            v-for="room of actualRoom.searchResult.otherRoom"
            :key="room.id"
            style="height: auto"
            class="col-sm-12 col-md-6 col-lg-4">
            <room-card :room="room" roomType="other" />
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div
          class="col-sm-12 col-md-6 col-lg-4"
          v-for="n in 6"
          :key="n"
          style="padding: 10px; min-height: 100px">
          <q-card style="height: 100%; width: 100%">
            <q-skeleton height="200px" square />
            <q-card-section>
              <q-skeleton type="text" width="30%" class="text-h6" />
              <q-skeleton type="text" class="text-subtitle1" />
              <q-skeleton type="text" width="50%" class="text-subtitle1" />
              <q-skeleton type="text" width="80%" class="text-subtitle1" />
              <div class="row justify-between q-mt-sm">
                <q-skeleton type="text" width="30%" class="text-subtitle1" />
                <q-skeleton type="QBtn" width="150px" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>

    <!-- <div v-else-if="actualRoom.selectedRoom">
      <room-card :room="actualRoom.selectedRoom" roomType="selected" />
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapActions, mapState } from "pinia";

//stores
import { useSearchStore } from "@/stores/searchStore";
import RoomCard from "./RoomCard.vue";

export default defineComponent({
  components: { RoomCard },

  computed: {
    ...mapState(useSearchStore, ["actualRoom"]),
  },
  methods: {
    ...mapActions(useSearchStore, ["searchRooms"]),
  },
  async created() {
    if (!this.actualRoom.selectedRoom) {
      await this.searchRooms();
    }
  },
});
</script>
