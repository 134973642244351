import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_room_card = _resolveComponent("room-card")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_room_card, {
      room: _ctx.room?.selectedRoom
    }, null, 8, ["room"]),
    _createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, {
          bordered: "",
          separator: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Гость ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Тариф ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Цена ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.room?.selectedRoom?.guests, (guest) => {
              return (_openBlock(), _createBlock(_component_q_item, {
                key: guest.idx
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(guest.age > -1
                  ? "Ребенок " + _ctx.formateAge(guest.age)
                  : "Взрослый " + guest.idx), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(guest.selectTariff?.tariffDescription), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(guest.selectTariff?.fullPrice.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            _createVNode(_component_q_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }),
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }),
                _createVNode(_component_q_item_section, { class: "text-weight-bolder" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.room?.addRoomResult?.roomSum.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}