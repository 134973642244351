import axios from "axios";
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;

const axiosInst = function () {
  const ai = axios.create();
  ai.defaults.timeout = 5000;
  if (process.env.NODE_ENV == "production") {
    ai.defaults.baseURL = window.onlBaseURL + "/client_data/";
  } else {
    ai.defaults.baseURL = "https://online-demo.resortshop.ru/client_data/";
  }

  //если есть onlAPIKey, то вставляем в заголовок
  if (window.onlAPIKey) {
    ai.defaults.headers.common["X-API-Key"] = window.onlAPIKey;
  }
  //если есть токен, то вставляем в заголовок
  const local_token = SessionStorage.getItem("token") as string;
  if (local_token) {
    ai.defaults.headers.common["Authorization"] = `Bearer ${local_token}`;
  }

  return ai;
};

export default axiosInst;
