<template>
  <q-dialog v-model="waitPayment" persistent>
    <q-card style="width: 500px">
      <q-card-section class="row align-center justify-center items-center">
        <div
          style="font-size: 30px; margin-bottom: 8px"
          class="col-12 text-center">
          Ожидание ответа от банка...
        </div>
        <div
          style="font-size: 15px; margin-bottom: 20px"
          class="col-12 text-center">
          Не закрывайте страницу!
        </div>

        <transition name="fade">
          <div v-if="redirectUrl" class="col-12 row justify-center q-mb-md">
            <q-btn @click="redirect()" size="sm" rounded
              >Перейти на страницу банка</q-btn
            >
          </div>
        </transition>

        <q-spinner-clock color="primary" size="60px" />
      </q-card-section>

      <q-card-actions class="row justify-center"
        ><q-btn @click="cancelPayment()" size="sm" flat>Отменить оплату</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { mapState, mapActions } from "pinia";
import { usePaymentStore } from "@/stores/paymentStore";
import { openURL } from "quasar";

export default defineComponent({
  computed: {
    ...mapState(usePaymentStore, ["waitPayment", "redirectUrl"]),
  },
  methods: {
    ...mapActions(usePaymentStore, ["cancelPayment"]),
    redirect() {
      openURL(this.redirectUrl);
    },
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  transition-delay: 2000ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
