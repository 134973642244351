<template>
  <div>
    <div>
      <q-btn
        ref="changeRoom"
        id="changeRoomBtn"
        icon="mdi-undo-variant"
        flat
        @click="
          delete actualRoom.selectedRoom;
          actualRoom.roomStep = 'room';
        "
        >Изменить номер
      </q-btn>
    </div>

    <q-tabs
      v-model="tab"
      dense
      class="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator>
      <q-tab
        v-for="guest in actualRoom.selectedRoom?.guests"
        :key="guest.idx"
        :name="guest.idx"
        :label="
          guest.age === -1
            ? `Взрослый ${guest.idx}`
            : `Ребенок ${formateAge(guest.age)}`
        " />
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel
        class="row justify-center"
        v-for="guest in actualRoom.selectedRoom?.guests"
        :key="guest.idx"
        :name="guest.idx">
        <!-- тариф -->
        <div
          class="col-sm-12 col-md-6"
          :class="{
            'q-pl-lg': !$q.screen.lt.md,
            'q-pt-lg': !$q.screen.lt.md,
            'q-pb-lg': $q.screen.lt.md,
          }"
          v-for="tariff in guest.tariffs"
          :key="tariff.tariffId">
          <q-card
            :style="{ height: $q.screen.lt.md ? 'auto' : '100%' }"
            class="row justify-between full">
            <q-img
              v-if="
                actualRoom.searchResult?.availableTariff.find(
                  (avTariff) => avTariff.id == tariff.tariffId
                )?.promotion_file
              "
              class="q-ma-sm"
              style="position: absolute; z-index: 2; right: 0px"
              width="50px"
              height="50px"
              :src="
                baseUrl +
                '/client_data/image/' +
                actualRoom.searchResult?.availableTariff.find(
                  (avTariff) => avTariff.id == tariff.tariffId
                )?.promotion_file
              " />
            <!-- лево -->
            <q-card-section class="col-sm-8 col-xs-12">
              <div style="font-size: x-large; margin-right: 50px">
                {{ tariff.tariffDescription }}
              </div>
              <q-separator style="margin-top: 5px; margin-bottom: 5px" />
              <div
                style="white-space: pre-line"
                v-html="
                  actualRoom.searchResult?.availableTariff.find(
                    (avTariff) => avTariff.id == tariff.tariffId
                  )?.info
                "></div>
            </q-card-section>

            <!-- право -->
            <div class="col-sm-3 col-xs-12 row justify-end items-end q-pa-md">
              <div>
                <div style="font-size: larger; text-align: end">
                  Стоимость за
                </div>

                <div style="font-size: larger; text-align: end">
                  {{ formattedSelectedDate.days }}
                  {{ formateDayNight(formattedSelectedDate.days) }}
                </div>

                <div
                  v-if="tariff.show_discount"
                  class="text-strike text-negative text-subtitle1 text-right">
                  {{ discountFormat(tariff.fullPrice, tariff.show_discount) }}
                </div>

                <div class="text-h6 text-weight-bold text-right">
                  {{
                    tariff.fullPrice.toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })
                  }}
                </div>
                <div class="row justify-end q-mt-sm">
                  <q-btn
                    style="width: 120px; font-size: 16px"
                    v-if="guest.selectTariff !== tariff"
                    @click="
                      guest.selectTariff = tariff;
                      nextTariff();
                    ">
                    Выбрать
                  </q-btn>

                  <q-btn color="primary" v-else @click="nextTariff()"
                    >Продолжить</q-btn
                  >
                </div>
              </div>
            </div>
          </q-card>
        </div>

        <!-- недоступные -->
        <div
          :class="{
            'q-pl-lg': !$q.screen.lt.md,
            'q-pt-lg': !$q.screen.lt.md,
            'q-pb-lg': $q.screen.lt.md,
          }"
          class="col-sm-12 col-md-6"
          v-for="tariff in actualRoom.searchResult?.otherTariff"
          :key="tariff.id">
          <q-card
            :style="{ height: $q.screen.lt.md ? 'auto' : '100%' }"
            class="row justify-between full">
            <!-- лево -->
            <q-card-section class="col-sm-8 col-xs-12">
              <div style="font-size: x-large">
                {{ tariff.description }}
              </div>
              <q-separator style="margin-top: 5px; margin-bottom: 5px" />
              <div style="white-space: pre-line" v-html="tariff.info"></div>
            </q-card-section>

            <q-banner
              style="width: 100%"
              dense
              class="bg-grey-2 text-negative text-center q-mt-sm">
              {{ tariff.otherDescription }}
            </q-banner>
          </q-card>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script lang="ts">
import { useSearchStore } from "@/stores/searchStore";
import { useSessionStore } from "@/stores/sessionStore";

import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";

import VueScrollTo from "vue-scrollto";

export default defineComponent({
  computed: {
    ...mapState(useSearchStore, [
      "actualRoom",
      "step",
      "formattedSelectedDate",
    ]),

    ...mapState(useSessionStore, ["baseUrl"]),
  },
  methods: {
    ...mapActions(useSearchStore, ["addRoom", "formateAge", "formateDayNight"]),
    nextTariff() {
      const next = this.actualRoom.selectedRoom?.guests.find((guest) => {
        if (!guest.selectTariff) return true;
      });
      if (next) {
        this.tab = next.idx;
      } else {
        this.addRoom();
      }
    },

    discountFormat(fullPrice: number, showDiscount: number) {
      let cur;
      cur = Math.round(((fullPrice / (100 - showDiscount)) * 100) / 10) * 10;

      // если цена с копейками то без округления до 10 рублей.
      if (Math.round(fullPrice) !== fullPrice) {
        cur = Math.round((fullPrice / (100 - showDiscount)) * 100);
      }
      return cur.toLocaleString("ru-RU", {
        style: "currency",
        currency: "RUB",
      });
    },
  },
  data: () => ({
    tab: 1,
  }),
  created() {
    if (window.onlDefaults?.autoSelectSingleTariff) {
      if (this.actualRoom.selectedRoom) {
        for (const guest of this.actualRoom.selectedRoom.guests) {
          if (guest.tariffs.length === 1) guest.selectTariff = guest.tariffs[0];
        }
      }
    }

    this.nextTariff();
  },

  mounted() {
    // const el = this.$refs["changeRoom"];
    // if (el) VueScrollTo.scrollTo(el, 1000, { offset: -20 });
    setTimeout(() => {
      console.log("scroll changeRoomBtn");

      VueScrollTo.scrollTo("#changeRoomBtn", 1000, { offset: -20 });
    }, 700);

    // console.log(el);
  },
});
</script>

<style>
.q-tab-panel {
  padding-left: 2px !important;
  padding-right: 5px !important;
}
</style>
